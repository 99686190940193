* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Rubik';
}

html {
    font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
}

a {
    text-decoration: none;
    &:link {
        color: inherit;
    }
    &:visited {
        color: inherit;
    }
}

iframe {
    border: unset;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

*::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: unset;
}
*::-webkit-scrollbar-thumb {
    height: 20%;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #e4e8ee;
}
*::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
*::-webkit-scrollbar-corner {
    background-color: transparent;
}

@media (max-width: 1024px) {
    *::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

input[type="color" i]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color" i]::-webkit-color-swatch {
    border: unset;
}