@font-face {
  font-family: 'Nunito';
  font-display: block;
  font-weight: 300;
  src: url("../../fonts/Nunito-Light.woff2") format('woff2'),url("../../fonts/Nunito-Light.woff") format('woff');
}
@font-face {
  font-family: 'Nunito';
  font-display: block;
  font-weight: 400;
  src: url("../../fonts/Nunito-Regular.woff2") format('woff2'),url("../../fonts/Nunito-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Nunito';
  font-display: block;
  font-weight: 600;
  src: url("../../fonts/Nunito-SemiBold.woff2") format('woff2'),url("../../fonts/Nunito-SemiBold.woff") format('woff');
}
@font-face {
  font-family: 'Nunito';
  font-display: block;
  font-weight: 700;
  src: url("../../fonts/Nunito-Bold.woff") format('woff2'),url("../../fonts/Nunito-Bold.woff") format('woff');
}
@font-face {
  font-family: 'Nunito';
  font-display: block;
  font-weight: 800;
  src: url("../../fonts/Nunito-ExtraBold.woff2") format('woff2'),url("../../fonts/Nunito-ExtraBold.woff") format('woff');
}

@font-face {
  font-family: 'Rubik';
  font-display: block;
  font-weight: 300;
  src: url("../../fonts/Rubik-Light.woff2") format('woff2'),url("../../fonts/Rubik-Light.woff") format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-display: block;
  font-weight: 400;
  src: url("../../fonts/Rubik-Regular.woff2") format('woff2'),url("../../fonts/Rubik-Regular.woff") format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-display: block;
  font-weight: 500;
  src: url("../../fonts/Rubik-Medium.woff2") format('woff2'),url("../../fonts/Rubik-Medium.woff") format('woff');
}
@font-face {
  font-family: 'Rubik';
  font-display: block;
  font-weight: 600;
  src: url("../../fonts/Rubik-Bold.woff2") format('woff2'),url("../../fonts/Rubik-Bold.woff") format('woff');
}